<template>
  <div class="scan">
    <div class="nav">
      <a class="close" @click="() => $router.go(-1)"></a>
      <p class="title">扫码登陆</p>
    </div>
    <div class="scroll-container">
      <Scaner v-on:code-scanned="codeScanned" v-on:error-captured="errorCaptured" :stop-on-scanned="true" :draw-on-found="true" :responsive="false" />
    </div>
  </div>
</template>

<script>
import Scaner from "./Scaner";
import { Dialog } from "vant";
import { loginGuest } from "@/api/user.js";
export default {
  name: "Scan",
  components: {
    Scaner,
  },
  data() {
    return {
      errorMessage: "",
    };
  },
  methods: {
    codeScanned(code) {
      this.login(code);
    },
    //登陆
    async login(qrurl) {
      this.$toast.loading({
        message: "登陆中...",
        duration: 0,
        forbidClick: true,
      });
      let Uid = localStorage.getItem("Uid");
      if (!Uid) {
        this.$store.dispatch("app/GET_UID");
      }
      let ret = await this.$Api(loginGuest, {
        certificate: qrurl,
        devID: this.$store.getters.Uid,
      });
      this.$toast.clear();
      if (ret && ret.code === 200) {
        localStorage.setItem("Authorization", ret.data.token);
        await this.$store.dispatch("user/getUserInfo");
        let toPath = sessionStorage.getItem("toPath");
        if (toPath) {
          this.$router.push(toPath);
        } else {
          this.$router.push("/mine");
        }
      } else {
        this.$toast(ret.tip || "登录失败");
      }
    },
    errorCaptured(error) {
      switch (error.name) {
        case "NotAllowedError":
          this.errorMessage = "相机权限被拒绝。";
          break;
        case "NotFoundError":
          this.errorMessage = "没有连接的相机。";
          break;
        case "NotSupportedError":
          this.errorMessage =
            "Seems like this page is served in non-secure context.";
          break;
        case "NotReadableError":
          this.errorMessage = "无法访问您的相机。 它已经在使用了吗？";
          break;
        case "OverconstrainedError":
          this.errorMessage = "约束与任何已安装的相机都不匹配。";
          break;
        default:
          this.errorMessage = "UNKNOWN ERROR: " + error.message;
      }
      console.error(this.errorMessage);
      Dialog.alert({
        title: "温馨提示",
        message: this.errorMessage,
        theme: "round-button",
      }).then(() => {});
    },
  },
  mounted() {
    var str = navigator.userAgent.toLowerCase();
    var ver = str.match(/cpu iphone os (.*?) like mac os/);
    if (ver && ver[1].replace(/_/g, ".") < "10.3.3") {
      Dialog.alert({
        title: "温馨提示",
        message: "打开相机失败",
        theme: "round-button",
      }).then(() => {
        // on close
      });
    }
  },
};
</script>

<style lang="css" scoped>
.scan {
  height: 100%;
  width: 100%;
}
.scan .nav {
  width: 100%;
  height: 47px;
  line-height: 47px;
  position: fixed;
  top: 0;
  left: 0;
  background: #000;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.scan .nav .title {
  padding: 0;
  margin: 0;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
}
.scan .nav .close {
  display: inline-block;
  height: 22px;
  width: 22px;
  background: url("../../../../assets/png/back.png") no-repeat center;
  background-size: auto 100%;
  position: absolute;
  left: 16px;
  top: 14px;
}
</style>